var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import dispatch from './dispatchTrackingEvent';
// eslint-disable-line  @typescript-eslint/no-explicit-any
var callAll = function () {
    var fns = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        fns[_i] = arguments[_i];
    }
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return fns.forEach(function (fn) { return fn && fn.apply(void 0, __spreadArray([], __read(args), false)); });
    };
};
function getEventCategory(nodeName) {
    switch (nodeName.toLowerCase()) {
        case 'a':
            return 'link';
        case 'img':
            return 'image';
        default:
            return nodeName.toLowerCase();
    }
}
function getURLPath(url) {
    url = new URL(url);
    return url.href.replace(url.origin, '');
}
/**
 * General method to dispatch tracking events from component.
 * It will try to get as information from the DOM event as possible,
 * otherwise will fall back to component props or overrides
 *
 * @param {Object} componentProps Component props
 * @param {Object} overrides Overrides for event data structure
 * @param {Object} event React synthetic event
 */
function dispatchTrackingEvent(componentProps, overrides, event) {
    if (event.defaultPrevented || event.nativeEvent.trackingPreventDefault) {
        return;
    }
    var eventData = overrides.eventData, eventProps = __rest(overrides, ["eventData"]);
    var targetUrl = event.currentTarget.action ||
        event.currentTarget.href ||
        componentProps.href;
    var label = event.currentTarget.alt ||
        event.currentTarget.getAttribute('aria-label') ||
        event.currentTarget.textContent;
    var trackingData = __assign(__assign({ eventCategory: getEventCategory(event.currentTarget.nodeName), eventAction: event.type }, eventProps), { eventData: __assign(__assign(__assign({}, (targetUrl && { targetUrl: getURLPath(targetUrl) })), (event.type !== 'submit' && label && { label: label })), eventData) });
    dispatch(trackingData);
}
/**
 * New PageApps method to dispatch tracking events from component.
 *
 * @param {Object} trackerProps Tracker props
 * @param {Object} event React synthetic event
 */
function dispatchPageAppTrackingEvent(trackerProps, event) {
    if (event.defaultPrevented || event.nativeEvent.trackingPreventDefault) {
        return;
    }
    var trackingData = __assign({ eventCategory: getEventCategory(event.currentTarget.nodeName), eventAction: event.type }, trackerProps);
    dispatch(trackingData);
}
/**
 * More specific tracker for CMS blocks in top navigation. Handles
 * a couple of corner cases when tracking inner html of a component
 * inserted with dangerouslySetInnerHTML.
 *
 * Might be considered a hack for now, probably can be changed when
 * those CMS blocks are migrated to Prismic, as this will
 * allow us to attach listeners directly to elements
 *
 * @param {Object} componentProps Component props
 * @param {Object} overrides Overrides for event data structure
 * @param {null|Array<string>} filterElementsList Trigger event only for the provided DOM Nodes
 * @param {Object} event React synthetic event
 */
// eslint-disable-next-line complexity,max-params
function dispatchCMSTrackingEvent(componentProps, overrides, filterElementsList, event) {
    // If the event was prevented, or the tracking dispatch was prevented
    // with a special flag on an native event object, we will stop here
    if (event.defaultPrevented || event.nativeEvent.trackingPreventDefault) {
        return;
    }
    // If filter element list was provided and our target element is not
    // in the list, we will stop here
    if (filterElementsList !== null &&
        !filterElementsList.includes(event.target.nodeName)) {
        return;
    }
    // ... otherwise, normalize the data and push the event
    var eventData = overrides.eventData, eventProps = __rest(overrides, ["eventData"]);
    var target = event.target.parentNode.nodeName === 'A'
        ? event.target.parentNode
        : event.target;
    var targetUrl = target.href || componentProps.href;
    var image = target.getElementsByTagName('img')[0];
    var label = (image && image.alt) ||
        event.currentTarget.getAttribute('aria-label') ||
        target.textContent;
    var trackingData = __assign(__assign({ eventCategory: getEventCategory((image && image.nodeName) || target.nodeName), eventAction: event.type }, eventProps), { eventData: __assign(__assign(__assign({}, (targetUrl && { targetUrl: getURLPath(targetUrl) })), (label && { label: label })), eventData) });
    dispatch(trackingData);
}
export function getTrackerProps(_a, overrides) {
    if (_a === void 0) { _a = {}; }
    var onClick = _a.onClick, onSubmit = _a.onSubmit, componentProps = __rest(_a, ["onClick", "onSubmit"]);
    if (overrides === void 0) { overrides = {}; }
    return __assign({ onClick: callAll(onClick, dispatchTrackingEvent.bind(null, componentProps, overrides)), onSubmit: callAll(onSubmit, dispatchTrackingEvent.bind(null, componentProps, overrides)) }, componentProps);
}
export function getPageAppTrackerProps(_a, trackerProps) {
    if (_a === void 0) { _a = {}; }
    var onClick = _a.onClick, onSubmit = _a.onSubmit, onChange = _a.onChange, componentProps = __rest(_a, ["onClick", "onSubmit", "onChange"]);
    if (trackerProps === void 0) { trackerProps = {}; }
    return __assign({ onClick: callAll(onClick, dispatchPageAppTrackingEvent.bind(null, trackerProps)), onSubmit: callAll(onSubmit, dispatchPageAppTrackingEvent.bind(null, trackerProps)), onChange: callAll(onChange, dispatchPageAppTrackingEvent.bind(null, trackerProps)) }, componentProps);
}
export function getCMSTrackerProps(_a, overrides, filterElementsList) {
    if (_a === void 0) { _a = {}; }
    var onClick = _a.onClick, onSubmit = _a.onSubmit, componentProps = __rest(_a, ["onClick", "onSubmit"]);
    if (overrides === void 0) { overrides = {}; }
    if (filterElementsList === void 0) { filterElementsList = null; }
    return __assign({ onClick: callAll(onClick, dispatchCMSTrackingEvent.bind(null, componentProps, overrides, filterElementsList)), onSubmit: callAll(onSubmit, dispatchCMSTrackingEvent.bind(null, componentProps, overrides, filterElementsList)) }, componentProps);
}
