var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { display, width as systemWidth, height } from 'styled-system';
import { useIsImageLoaded } from '../../../hooks/image';
import { objectFit } from '../../../theme/system-utilities';
import { getSrcSetAttrs } from '../../../utils/responsive-image/srcset';
export var IMAGES_ABOVE_THE_FOLD = 6;
var Container = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), display, systemWidth, height);
Container.defaultProps = {
    display: 'block',
};
var Img = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), systemWidth, height, objectFit);
Img.defaultProps = {
    objectFit: 'contain',
};
var ProductImage = function (_a) {
    var alt = _a.alt, imgIndex = _a.imgIndex, src = _a.src, widths = _a.widths, fallbackSize = _a.fallbackSize;
    var isBelowTheFold = imgIndex >= IMAGES_ABOVE_THE_FOLD;
    var _b = useIsImageLoaded(isBelowTheFold), ref = _b.ref, isLoaded = _b.isLoaded;
    var srcSetAttrs = useMemo(function () { return getSrcSetAttrs({ src: src, widths: widths, fallbackSize: fallbackSize }); }, [src, widths]);
    var styledResponsiveSize = useMemo(function () { return widths.map(function (width) { return "".concat(width, "px"); }); }, [widths]);
    var styledSizeProps = {
        width: styledResponsiveSize,
        height: styledResponsiveSize,
    };
    return (React.createElement(Container, __assign({}, styledSizeProps, { ref: ref }), isLoaded && (React.createElement(Img, __assign({}, (isBelowTheFold && { loading: 'lazy' }), srcSetAttrs, styledSizeProps, { alt: alt })))));
};
export default ProductImage;
var templateObject_1, templateObject_2;
