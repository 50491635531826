var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { useRef, useEffect, useState } from 'react';
import { dispatchProductViewEvent } from './productViewEvent';
var transformTrackingArticle = function (articles) {
    return articles.map(function (_a, index) {
        var tracking = _a.tracking, sku = _a.sku, sponsored = _a.sponsored, dyDecisionId = _a.dyDecisionId, dySlotId = _a.dySlotId;
        return ({
            tracking: tracking,
            sku: sku,
            sponsored: sponsored,
            dyDecisionId: dyDecisionId,
            dySlotId: dySlotId,
            index: index,
        });
    });
};
export var useObserveArticles = function (_a) {
    var articles = _a.articles, listType = _a.listType, _b = _a.dataTestId, dataTestId = _b === void 0 ? 'product-item' : _b, _c = _a.debounce, debounce = _c === void 0 ? 0 : _c;
    var observedSkusRef = useRef();
    var intersectionObserverRef = useRef();
    var productsSkuNodeMapRef = useRef();
    var articlesRef = useRef(articles);
    var _d = __read(useState([]), 2), articlesToTrack = _d[0], setArticlesToTrack = _d[1];
    var handleTracking = function (articlesList) {
        if (articlesList.length) {
            dispatchProductViewEvent(transformTrackingArticle(articlesList), listType);
        }
    };
    useEffect(function () {
        if (debounce) {
            var handler_1 = setTimeout(function () {
                handleTracking(articlesToTrack);
            }, debounce);
            return function () { return clearTimeout(handler_1); };
        }
        else {
            handleTracking(articlesToTrack);
        }
    }, [articlesToTrack, debounce]);
    useEffect(function () {
        var isMounted = true;
        observedSkusRef.current = new Set();
        productsSkuNodeMapRef.current = new Map();
        intersectionObserverRef.current = new IntersectionObserver(function (entries) {
            var intersectedSkus = [];
            entries.forEach(function (entry) {
                var _a;
                if (entry.isIntersecting || entry.intersectionRatio > 0) {
                    var sku = entry.target.dataset.sku;
                    intersectedSkus.push(sku !== null && sku !== void 0 ? sku : '');
                    (_a = intersectionObserverRef.current) === null || _a === void 0 ? void 0 : _a.unobserve(entry.target);
                }
            });
            var currentArticles = articlesRef.current;
            var viewArticlesTracking = currentArticles.filter(function (_a) {
                var sku = _a.sku;
                return intersectedSkus.includes(sku);
            });
            if (!isMounted) {
                return;
            }
            setArticlesToTrack(viewArticlesTracking);
        });
        return function () {
            var _a;
            isMounted = false;
            (_a = intersectionObserverRef.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        };
    }, []);
    useEffect(function () {
        var e_1, _a, e_2, _b;
        var _c, _d, _e, _f, _g, _h;
        articlesRef.current = articles;
        var observedSkus = new Set(observedSkusRef.current);
        var items = Array.from(document.querySelectorAll("[data-testid=\"".concat(dataTestId, "\"]")));
        var newProductsSkuNodeMap = new Map();
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                var sku = (_c = item.dataset.sku) !== null && _c !== void 0 ? _c : '';
                newProductsSkuNodeMap.set(sku, item);
                if (observedSkus.has(sku)) {
                    observedSkus.delete(sku);
                }
                else {
                    (_d = intersectionObserverRef.current) === null || _d === void 0 ? void 0 : _d.observe(item);
                    (_e = observedSkusRef.current) === null || _e === void 0 ? void 0 : _e.add(sku);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var observedSkus_1 = __values(observedSkus), observedSkus_1_1 = observedSkus_1.next(); !observedSkus_1_1.done; observedSkus_1_1 = observedSkus_1.next()) {
                var unusedSku = observedSkus_1_1.value;
                (_f = observedSkusRef.current) === null || _f === void 0 ? void 0 : _f.delete(unusedSku);
                var removedProductNode = (_g = productsSkuNodeMapRef.current) === null || _g === void 0 ? void 0 : _g.get(unusedSku);
                if (removedProductNode) {
                    (_h = intersectionObserverRef.current) === null || _h === void 0 ? void 0 : _h.unobserve(removedProductNode);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (observedSkus_1_1 && !observedSkus_1_1.done && (_b = observedSkus_1.return)) _b.call(observedSkus_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        productsSkuNodeMapRef.current = newProductsSkuNodeMap;
    }, [articles]);
};
