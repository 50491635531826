var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { backgroundColor, borderRadius, themeGet, size } from 'styled-system';
import { useWishlist } from '../../hooks/wishlist';
import { useLocalization } from '../../providers/LocaleProvider';
import { pulse } from '../../theme/keyframes';
import { cursor, themeHeight, themeWidth } from '../../theme/system-utilities';
import { IconButton } from '../Button/Icon';
import Icon from '../Icon';
var IconColor;
(function (IconColor) {
    IconColor["Inactive"] = "colors.sys.neutral.icon.default";
    IconColor["Hover"] = "colors.sys.neutral.icon.hover";
    IconColor["Active"] = "colors.sys.primary.icon.active";
})(IconColor || (IconColor = {}));
var ICON_SIZE_S = 24;
var ICON_SIZE_XL = 32;
var Container = styled(IconButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus-visible {\n    outline-offset: -6px;\n  }\n\n  &:hover > svg {\n    stroke: ", ";\n  }\n\n  &[aria-checked='true']:hover > svg {\n    stroke: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus-visible {\n    outline-offset: -6px;\n  }\n\n  &:hover > svg {\n    stroke: ", ";\n  }\n\n  &[aria-checked='true']:hover > svg {\n    stroke: ", ";\n  }\n"])), borderRadius, cursor, size, backgroundColor, themeGet(IconColor.Hover), themeGet(IconColor.Active));
Container.defaultProps = __assign(__assign({}, IconButton.defaultProps), { borderRadius: 'circle', cursor: 'pointer' });
var iconAnimationCSS = function (_a) {
    var isActive = _a.isActive;
    return isActive && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    animation: ", " 1s ease;\n  "], ["\n    animation: ", " 1s ease;\n  "])), pulse);
};
var StyledWishlistIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  fill: ", ";\n  stroke: ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  fill: ", ";\n  stroke: ", ";\n"])), size, themeWidth, themeHeight, iconAnimationCSS, function (_a) {
    var isActive = _a.isActive;
    return isActive ? themeGet(IconColor.Active) : 'none';
}, function (_a) {
    var isActive = _a.isActive;
    return themeGet(isActive ? IconColor.Active : IconColor.Inactive);
});
StyledWishlistIcon.defaultProps = {
    size: [ICON_SIZE_S, null, null, ICON_SIZE_XL],
};
var WishListButton = function (_a) {
    var sku = _a.sku, tracking = _a.tracking, index = _a.index, iconProps = _a.iconProps, props = __rest(_a, ["sku", "tracking", "index", "iconProps"]);
    var t = useLocalization().t;
    var _b = useWishlist({
        sku: sku,
        tracking: tracking,
        position: index,
    }), isWishlisted = _b.isWishlisted, isInProgress = _b.isInProgress, clickHandler = _b.clickHandler;
    return (React.createElement(Container, __assign({ "data-testid": "wishlist-button", onClick: clickHandler, "aria-label": isWishlisted ? t('h24_remove_from_wishlist') : t('h24_add_to_wishlist'), role: "checkbox", disabled: isInProgress, "aria-checked": isWishlisted }, props),
        React.createElement(StyledWishlistIcon, __assign({ name: "wishlist", isActive: isWishlisted }, iconProps))));
};
export default WishListButton;
var templateObject_1, templateObject_2, templateObject_3;
