var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, alignSelf, color, display, flexDirection, fontSize, fontWeight, lineHeight, space, } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { textDecoration, whiteSpace } from '../../theme/system-utilities';
import EnergyClass from '../EnergyClass';
import { Flex } from '../Flex';
import ArticlePriceOrder from './ArticlePriceOrder';
import { Price as BasePrice } from './BasePriceComponents';
export var RenderingStrategy;
(function (RenderingStrategy) {
    RenderingStrategy[RenderingStrategy["Horizontal"] = 0] = "Horizontal";
    RenderingStrategy[RenderingStrategy["Vertical"] = 1] = "Vertical";
})(RenderingStrategy || (RenderingStrategy = {}));
var ArticleSmallPriceContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, alignItems, fontSize, lineHeight);
var articleSmallPriceContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    fontSize: ['fs_14', 'fs_16', null, 'fs_18'],
    lineHeight: ['lh_20', null, null, 'lh_24'],
};
var HorizontalPriceContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var VerticalPriceContainer = styled(Flex)(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var verticalPriceContainerStyles = __assign(__assign({}, Flex.defaultProps), { justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center' });
var VerticalEELPriceContainer = styled(Flex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n\n  > span {\n    ", ";\n  }\n"], ["\n  ", ";\n\n  > span {\n    ", ";\n  }\n"])), flexDirection, alignSelf);
var verticalEELPriceContainerStyles = {
    flexDirection: 'column',
    alignSelf: 'flex-start',
};
var EnergyClassContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
var energyClassContainerStyles = {
    mr: ['sp_4', null, null, 'sp_8'],
};
var Price = styled(BasePrice)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), fontWeight, whiteSpace, fontSize, lineHeight);
var priceStyles = __assign(__assign({}, BasePrice.defaultProps), { alignSelf: ['flex-start', 'center'], pr: 'sp_4', fontWeight: 'bold', fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_24', null, null, 'lh_28'], whiteSpace: 'nowrap' });
var orderedPriceStyles = {
    fontSize: ['fs_14', 'fs_16', 'fs_16', 'fs_18'],
    lineHeight: ['lh_20', 'lh_24', 'lh_24', 'lh_28'],
};
var OldPriceContainer = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), lineHeight, space);
var oldPriceContainerStyles = {
    lineHeight: 'lh_16',
    pr: 'sp_4',
};
var OldPrice = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, fontSize, lineHeight, space, whiteSpace, textDecoration);
var oldPriceStyles = {
    color: 'sys.neutral.text.active',
    fontSize: ['fs_14', null, null, 'fs_16'],
    lineHeight: 'lh_16',
    whiteSpace: 'nowrap',
    textDecoration: 'line-through',
};
var orderedOldPriceStyles = {
    fontSize: ['fs_12', 'fs_14', 'fs_14', 'fs_16'],
    lineHeight: ['lh_16', 'lh_18', 'lh_18', 'lh_24'],
};
var getPriceContainer = function (isHorizontal, hasEnergyClass) {
    if (isHorizontal) {
        return HorizontalPriceContainer;
    }
    if (hasEnergyClass) {
        return VerticalEELPriceContainer;
    }
    return VerticalPriceContainer;
};
var getPriceContainerStyles = function (isHorizontal, hasEnergyClass) {
    if (isHorizontal) {
        return {};
    }
    if (hasEnergyClass) {
        return verticalEELPriceContainerStyles;
    }
    return verticalPriceContainerStyles;
};
var ArticleSmallPrice = function (_a) {
    var energyClass = _a.energyClass, energyClassHref = _a.energyClassHref, specialPrice = _a.specialPrice, regularPrice = _a.regularPrice, rrpPrice = _a.rrpPrice, renderingStrategy = _a.renderingStrategy, EcoMobilierLabel = _a.EcoMobilierLabel, measurement = _a.measurement, isOrdered = _a.isOrdered, className = _a.className;
    var fc = useLocalization().fc;
    var hasEnergyClass = !!energyClass;
    var isOnSale = specialPrice && regularPrice && specialPrice.value !== regularPrice.value;
    var currentPrice = isOnSale ? specialPrice : regularPrice;
    var isHorizontal = renderingStrategy === RenderingStrategy.Horizontal;
    var shouldDisplayRRP = !specialPrice && !!rrpPrice;
    var PriceContainer = getPriceContainer(isHorizontal, hasEnergyClass);
    var priceContainerStyles = getPriceContainerStyles(isHorizontal, hasEnergyClass);
    return (React.createElement(ArticleSmallPriceContainer, __assign({}, articleSmallPriceContainerStyles, { className: className }),
        hasEnergyClass && (React.createElement(EnergyClassContainer, __assign({}, energyClassContainerStyles),
            React.createElement(EnergyClass, __assign({}, energyClass, { href: energyClassHref })))),
        React.createElement(PriceContainer, __assign({}, priceContainerStyles),
            currentPrice && (React.createElement(Price, __assign({ isOnSale: isOnSale, "data-testid": "current-price" }, priceStyles, (isOrdered && __assign({}, orderedPriceStyles))), fc(currentPrice.value))),
            isOnSale && (React.createElement(OldPriceContainer, __assign({}, oldPriceContainerStyles),
                React.createElement(OldPrice, __assign({}, oldPriceStyles, (isOrdered && __assign({}, orderedOldPriceStyles)), { "data-testid": "old-price" }),
                    React.createElement("span", { "data-testid": "old-price-amount" }, fc(regularPrice.value))))),
            React.createElement(ArticlePriceOrder, { shouldDisplayRRP: shouldDisplayRRP, measurement: measurement, rrpPrice: rrpPrice, isOrdered: isOrdered }),
            EcoMobilierLabel)));
};
export default ArticleSmallPrice;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
