var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { breakpoints } from '../../theme/theme';
import { transformOriginalImageUrl, isOriginalImageUrl } from '../cdn/image';
import { transformPrismicImageUrl } from '../cdn/prismic';
export var getFallbackSrc = function (_a) {
    var src = _a.src, fallbackSize = _a.fallbackSize;
    if (!fallbackSize) {
        return src;
    }
    return isOriginalImageUrl(src)
        ? transformOriginalImageUrl(src, { width: fallbackSize })
        : transformPrismicImageUrl(src, fallbackSize);
};
export var getSrcSetUrl = function (_a) {
    var src = _a.src, width = _a.width, quality = _a.quality;
    return isOriginalImageUrl(src)
        ? transformOriginalImageUrl(src, __assign({ width: width }, (quality && { quality: quality })))
        : transformPrismicImageUrl(src, width);
};
var getDecimalInt = function (breakpoint) { return parseInt(breakpoint, 10); };
var getBreakpointEdge = function (breakpoint) {
    return "".concat(getDecimalInt(breakpoint) - 1, "px");
};
var devicePixelRatios = [1, 2];
var computeAvailableWidths = function (widths) {
    var entries = []
        .concat.apply([], __spreadArray([], __read(widths.map(function (width) { return devicePixelRatios.map(function (ratio) { return ratio * width; }); })), false)).sort(function (a, b) { return a - b; });
    var setOfEntries = new Set(entries);
    return Array.from(setOfEntries);
};
var getSrcSet = function (src, widths) {
    return computeAvailableWidths(widths).map(function (width) {
        var srcSetPart = isOriginalImageUrl(src)
            ? transformOriginalImageUrl(src, { width: width })
            : transformPrismicImageUrl(src, width);
        return "".concat(srcSetPart, " ").concat(width, "w");
    });
};
var getMediaMin = function (minWidth) { return "(min-width: ".concat(minWidth, ")"); };
var getMediaMax = function (maxWidth) {
    return "(max-width: ".concat(getBreakpointEdge(maxWidth), ")");
};
var getSizes = function (widths) {
    var breakPointMediaQueries = [];
    var _a = __read(widths, 1), width = _a[0];
    if (widths.length > 1) {
        breakpoints.slice(0, -1).forEach(function (breakpoint, i) {
            if (widths[i + 2]) {
                breakPointMediaQueries.push("".concat(getMediaMin(breakpoint), " and ").concat(getMediaMax(breakpoints[i + 1]), " ").concat(widths[i + 1], "px"));
            }
        });
        var mediaQueriesString = breakPointMediaQueries.length > 0
            ? "".concat(breakPointMediaQueries.join(', '), ", ")
            : '';
        return "".concat(getMediaMax(breakpoints[0]), " ").concat(width, "px, ").concat(mediaQueriesString).concat(widths[widths.length - 1], "px");
    }
    return widths.length === 1 ? "".concat(width, "px") : '';
};
/**
 *
 * @deprecated
 * Please use common/components/ResponsiveImage instead.
 */
export var getSrcSetAttrs = function (_a) {
    var src = _a.src, widths = _a.widths, _b = _a.fallbackSize, fallbackSize = _b === void 0 ? widths[widths.length - 1] : _b;
    return ({
        srcSet: getSrcSet(src, widths),
        sizes: getSizes(widths),
        src: getFallbackSrc({ src: src, fallbackSize: fallbackSize }),
    });
};
