var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, backgroundColor, display, flexDirection, height, position, space, width, } from 'styled-system';
import { cursor, textDecoration, themeRight, themeTop } from '../../../theme/system-utilities';
import DefaultProductImage from '../../DefaultProductImage';
import DiscountLabels from '../../DiscountLabels';
import WishlistButton from '../../WishlistButton';
import { CONTENT_PRODUCT_CARD_IMG_SIZES, LABELS_POSITION_LEFT, LABELS_POSITION_TOP, WISHLIST_BUTTON_SIZE, } from '../constants';
import { getLinkTrackingProps } from '../tracking';
import { ContentProductCardDetails } from './ContentProductCardDetails';
import ProductImage from './ProductImage';
var ProductCardContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, width, height, space);
ProductCardContainer.defaultProps = {
    position: 'relative',
    pb: ['sp_24', 'sp_32', 'sp_48'],
    width: CONTENT_PRODUCT_CARD_IMG_SIZES,
    m: '0 auto',
};
var ProductCardInnerContainer = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), backgroundColor, width, display, flexDirection, alignItems, cursor, textDecoration);
ProductCardInnerContainer.defaultProps = {
    bg: 'sys.neutral.background.default',
    width: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
};
var StyledWishlistButton = styled(WishlistButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, themeRight, themeTop, space);
StyledWishlistButton.defaultProps = {
    position: 'absolute',
    themeRight: ['sp_4', null, null, 'sp_8'],
    themeTop: ['sp_4', null, null, 'sp_20'],
    height: WISHLIST_BUTTON_SIZE,
    width: WISHLIST_BUTTON_SIZE,
};
var ContentProductCard = function (_a) {
    var _b;
    var article = _a.article, index = _a.index, productEventDataListType = _a.productEventDataListType;
    var sku = article.sku, url = article.url, name = article.name, images = article.images, labels = article.labels, tracking = article.tracking, sponsored = article.sponsored, metrics = article.metrics;
    return (React.createElement(ProductCardContainer, null,
        React.createElement(DiscountLabels, { labels: labels, themeTop: LABELS_POSITION_TOP, themeLeft: LABELS_POSITION_LEFT }),
        React.createElement(StyledWishlistButton, { sku: sku, index: index, count: metrics === null || metrics === void 0 ? void 0 : metrics.wishlist.count, tracking: tracking, className: "product-card-wishlist" }),
        React.createElement(ProductCardInnerContainer, __assign({ "data-testid": "product-card-inner-container" }, getLinkTrackingProps({
            href: url,
            tracking: tracking,
            list: productEventDataListType,
            index: index,
            sponsored: sponsored,
        })),
            ((_b = images[0]) === null || _b === void 0 ? void 0 : _b.path) ? (React.createElement(ProductImage, { src: images[0].path, widths: CONTENT_PRODUCT_CARD_IMG_SIZES, fallbackSize: CONTENT_PRODUCT_CARD_IMG_SIZES[2], alt: name, imgIndex: index })) : (React.createElement(DefaultProductImage, { title: name, width: CONTENT_PRODUCT_CARD_IMG_SIZES, height: CONTENT_PRODUCT_CARD_IMG_SIZES, minWidth: CONTENT_PRODUCT_CARD_IMG_SIZES })),
            React.createElement(ContentProductCardDetails, { article: article }))));
};
export default ContentProductCard;
var templateObject_1, templateObject_2, templateObject_3;
