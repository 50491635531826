var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from 'react';
import { useIsObserved } from './intersection-observer';
export var useLoadingImageBackground = function (isLoaded, imageRef) {
    var _a = __read(useState(isLoaded ? undefined : 'sys.neutral.background.weak'), 2), backgroundColor = _a[0], setBackgroundColor = _a[1];
    var onImageLoad = useCallback(function () {
        setBackgroundColor('transparent');
    }, []);
    useEffect(function () {
        var _a, _b;
        // handle edge cases of image being loaded before getting the load event attached
        if ((_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.complete) {
            onImageLoad();
        }
        else {
            (_b = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('load', onImageLoad);
        }
        return function () {
            var _a;
            (_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('load', onImageLoad);
        };
    }, []);
    return { backgroundColor: backgroundColor, onImageLoad: onImageLoad };
};
export var useIsImageLoaded = function (isOutOfViewport) {
    var _a = __read(useIsObserved(), 2), ref = _a[0], isObserved = _a[1];
    var _b = __read(useState(isObserved || !isOutOfViewport), 2), isLoaded = _b[0], setIsLoaded = _b[1];
    useEffect(function () {
        if (HTMLImageElement.prototype.hasOwnProperty('loading') || isObserved) {
            setIsLoaded(true);
        }
    }, [isObserved]);
    return { ref: ref, isLoaded: isLoaded };
};
