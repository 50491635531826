var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, color, display, flexDirection, flexWrap, fontSize, justifyContent, lineHeight, maxWidth, space, textAlign, width, } from 'styled-system';
import { ENERGY_EFFICIENCY_HASH } from '../../../constants/energyClass';
import { RatingSize } from '../../../enums/rating';
import { useLocalization } from '../../../providers/LocaleProvider';
import { textTransform } from '../../../theme/system-utilities';
import ArticleSmallPrice from '../../Price/ArticleSmallPrice';
import Rating from '../../Rating';
import { Text } from '../../Text';
import { Sponsored } from '../Sponsored';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, flexDirection, alignItems, textAlign, width);
Container.defaultProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
};
var BrandName = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", "\n"], ["\n  ", ";\n  ", "\n"])), textTransform, maxWidth);
BrandName.defaultProps = {
    as: 'p',
    fontSize: ['fs_11', null, 'fs_12', 'fs_14'],
    lineHeight: ['lh_16', null, null, 'lh_20'],
    color: 'sys.neutral.border.default',
    textTransform: 'uppercase',
    maxWidth: '100%',
    mt: 'sp_4',
    mb: 'sp_2',
};
var ProductName = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, fontSize, lineHeight, space, width);
ProductName.defaultProps = {
    color: 'sys.neutral.border.strong',
    fontSize: ['fs_14', null, 'fs_16', 'fs_18'],
    lineHeight: ['lh_16', null, 'lh_20', 'lh_22'],
    mt: 'sp_0',
    mb: ['sp_4', null, 'sp_8'],
    width: '100%',
};
var FastDeliveryMessage = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), color, fontSize, lineHeight, space);
FastDeliveryMessage.defaultProps = {
    color: 'sys.success.background.default',
    fontSize: ['fs_12', null, 'fs_14', 'fs_18'],
    lineHeight: ['lh_16', null, null, 'lh_26'],
    mt: ['sp_4', null, 'sp_8'],
};
var StyledRating = styled(Rating)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), flexWrap, justifyContent, space);
StyledRating.defaultProps = {
    flexWrap: 'wrap',
    justifyContent: 'center',
    mt: ['sp_4', null, 'sp_8'],
};
var StyledSponsored = styled(Sponsored)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
StyledSponsored.defaultProps = {
    mt: ['sp_4', null, null, 'sp_8'],
};
export var ContentProductCardDetails = function (_a) {
    var _b;
    var _c = _a.article, url = _c.url, name = _c.name, _d = _c.prices, regular = _d.regular, special = _d.special, rrp = _d.rrp, measurement = _d.measurement, energyClass = _c.energyClass, delivery = _c.delivery, _e = _c.ratings, count = _e.count, average = _e.average, brand = _c.brand, sponsored = _c.sponsored;
    var t = useLocalization().t;
    return (React.createElement(Container, null,
        React.createElement(BrandName, { "data-testid": "product-brand" },
            (brand === null || brand === void 0 ? void 0 : brand.name) || '',
            "\u00A0"),
        React.createElement(ProductName, null, name),
        regular && (React.createElement(ArticleSmallPrice, { energyClass: energyClass, energyClassHref: "".concat(url).concat(ENERGY_EFFICIENCY_HASH), specialPrice: special, rrpPrice: rrp, regularPrice: regular, measurement: measurement })),
        ((_b = delivery === null || delivery === void 0 ? void 0 : delivery.flags) === null || _b === void 0 ? void 0 : _b.fastDelivery) && (React.createElement(FastDeliveryMessage, null, t('h24_immediately_available'))),
        !!average && (React.createElement(StyledRating, { className: "product-card-rating", size: RatingSize.Small, rate: average, count: count })),
        sponsored && React.createElement(StyledSponsored, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
