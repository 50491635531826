var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { fontSize, lineHeight, marginRight } from 'styled-system';
import RRPPrice from './RRPPrice';
import UnitPrice from './UnitPrice';
var StyledUnitPrice = styled(UnitPrice)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), marginRight, fontSize, lineHeight);
var styledUnitPrice = {
    marginRight: 'sp_4',
    fontSize: ['fs_12', 'fs_14', 'fs_14', 'fs_16'],
    lineHeight: ['lh_16', 'lh_18', 'lh_18', 'lh_24'],
};
var StyledRRPPrice = styled(RRPPrice)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), fontSize, lineHeight);
var styledRRPPrice = {
    fontSize: ['fs_12', 'fs_14', 'fs_14', 'fs_16'],
    lineHeight: ['lh_16', 'lh_18', 'lh_18', 'lh_24'],
};
var ArticlePriceOrder = function (_a) {
    var shouldDisplayRRP = _a.shouldDisplayRRP, rrpPrice = _a.rrpPrice, measurement = _a.measurement, isOrdered = _a.isOrdered;
    return (React.createElement(Fragment, null,
        shouldDisplayRRP && !isOrdered && React.createElement(RRPPrice, { rrpPrice: rrpPrice }),
        measurement && (React.createElement(StyledUnitPrice, __assign({ measurement: measurement }, (isOrdered && __assign({}, styledUnitPrice))))),
        shouldDisplayRRP && isOrdered && React.createElement(StyledRRPPrice, __assign({ rrpPrice: rrpPrice }, styledRRPPrice))));
};
export default ArticlePriceOrder;
var templateObject_1, templateObject_2;
