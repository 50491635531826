var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useRef, useState, useEffect, useCallback } from 'react';
var defaultObserverOptions = {
    root: null,
    rootMargin: '0px',
    threshold: [0],
    disconnectOnIntersection: true,
};
var createNewObserver = function (_a) {
    var observerRef = _a.observerRef, setIsIntersecting = _a.setIsIntersecting, _b = _a.options, _c = _b.disconnectOnIntersection, disconnectOnIntersection = _c === void 0 ? true : _c, options = __rest(_b, ["disconnectOnIntersection"]);
    return new IntersectionObserver(function (_a) {
        var _b = __read(_a, 1), isIntersecting = _b[0].isIntersecting;
        setIsIntersecting(isIntersecting);
        if (isIntersecting && disconnectOnIntersection) {
            observerRef.current.disconnect();
        }
    }, options);
};
var observeNode = function (_a) {
    var node = _a.node, observerRef = _a.observerRef, setIsIntersecting = _a.setIsIntersecting, options = _a.options;
    if (!observerRef.current) {
        observerRef.current = createNewObserver({
            observerRef: observerRef,
            options: options,
            setIsIntersecting: setIsIntersecting,
        });
    }
    if (node) {
        observerRef.current.observe(node);
    }
    else {
        observerRef.current.disconnect();
    }
};
export var useIsObserved = function (options) {
    if (options === void 0) { options = defaultObserverOptions; }
    var observerRef = useRef(null);
    var nodeRef = useRef(null);
    var _a = __read(useState(false), 2), isIntersecting = _a[0], setIsIntersecting = _a[1];
    var setNodeRef = useCallback(function (node) { return (nodeRef.current = node); }, []);
    useEffect(function () {
        if (nodeRef.current) {
            observeNode({
                node: nodeRef.current,
                observerRef: observerRef,
                options: options,
                setIsIntersecting: setIsIntersecting,
            });
        }
        return function () {
            var observer = observerRef.current;
            if (observer) {
                observer.disconnect();
            }
        };
    }, []);
    return [setNodeRef, isIntersecting];
};
export var useIsTargetObserved = function (targetRef, options) {
    if (options === void 0) { options = defaultObserverOptions; }
    var observerRef = useRef(null);
    var _a = __read(useState(false), 2), isIntersecting = _a[0], setIsIntersecting = _a[1];
    useEffect(function () {
        observeNode({
            node: targetRef.current,
            observerRef: observerRef,
            options: options,
            setIsIntersecting: setIsIntersecting,
        });
        return function () {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);
    return isIntersecting;
};
