var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { ProductEventDataList } from 'corgi-types';
import { width, space, textAlign } from 'styled-system';
import { ButtonLink } from '../../common/components/Button';
import { Grid, FlexJustifyContent } from '../../common/components/Grid';
import RichContent from '../../common/components/RichText/RichContent';
import ProductCardList from './ProductCardList';
var COLUMNS_COUNT = 4;
var Heading = styled(RichContent)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), textAlign, width, space);
Heading.defaultProps = {
    textAlign: 'left',
    width: 1,
    py: 'sp_8',
    mb: [null, 'sp_12', 'sp_16', 'sp_24'],
};
var SeeMoreButton = styled(ButtonLink)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
SeeMoreButton.defaultProps = __assign(__assign({}, ButtonLink.defaultProps), { kind: 'secondary', py: 'sp_8', px: 'sp_8' });
var ContentProductList = function (_a) {
    var heading = _a.heading, isHeadingCenterAligned = _a.isHeadingCenterAligned, ctaLabel = _a.ctaLabel, ctaHref = _a.ctaHref, ctaType = _a.ctaType, articles = _a.articles;
    return (React.createElement(Grid, { flexDirection: "column", justifyContent: FlexJustifyContent.CENTER },
        heading && (React.createElement(Heading, __assign({ content: heading }, (isHeadingCenterAligned && { textAlign: 'center' })))),
        React.createElement(ProductCardList, { articles: articles, columnsCount: COLUMNS_COUNT, productEventDataListType: ProductEventDataList.ContentList }),
        ctaHref && ctaLabel && (React.createElement(SeeMoreButton, { kind: ctaType, href: ctaHref }, ctaLabel))));
};
export default ContentProductList;
var templateObject_1, templateObject_2;
