import { useEffect, useCallback } from 'react';
import { KeyCode } from '../enums/Keyboard';
export var useKeyboardClose = function (isOpen, closeHandler) {
    useEffect(function () {
        if (isOpen) {
            var keyDownHandler_1 = function (_a) {
                var keyCode = _a.keyCode;
                return keyCode === KeyCode.Escape ? closeHandler() : null;
            };
            document.addEventListener('keydown', keyDownHandler_1);
            return function () { return document.removeEventListener('keydown', keyDownHandler_1); };
        }
    }, [isOpen, closeHandler]);
};
export var useKeyboardSelect = function (onSelect, callbackArg) {
    return useCallback(function (event) {
        if ([KeyCode.Enter, KeyCode.Space].includes(event.keyCode)) {
            event.preventDefault();
            event.stopPropagation();
            onSelect(callbackArg, event);
        }
    }, [onSelect, callbackArg]);
};
export var useKeyboardNavigation = function (_a) {
    var onGoNext = _a.onGoNext, onGoPrevious = _a.onGoPrevious, onSelect = _a.onSelect;
    return useCallback(function (event) {
        var actionPerformed = false;
        switch (event.keyCode) {
            case KeyCode.ArrowUp:
            case KeyCode.ArrowLeft:
                onGoPrevious();
                actionPerformed = true;
                break;
            case KeyCode.ArrowDown:
            case KeyCode.ArrowRight:
                onGoNext();
                actionPerformed = true;
                break;
            case KeyCode.Space:
                onSelect();
                actionPerformed = true;
                break;
        }
        if (actionPerformed) {
            event.preventDefault();
            event.stopPropagation();
        }
    }, [onGoNext, onGoPrevious, onSelect]);
};
