var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, height, width, minWidth, space, size, } from 'styled-system';
import { boxSizing, fill } from '../../theme/system-utilities';
import { Flex } from '../Flex';
import Icon from '../Icon';
var BG_SIZES = ['56px', '60px', '72px', '80px'];
var ICON_SIZES = [38, 40, 42];
var DefaultImageBackground = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), backgroundColor, minWidth, height, size, boxSizing);
DefaultImageBackground.defaultProps = {
    bg: 'sys.neutral.background.weaker',
    minWidth: BG_SIZES,
    height: BG_SIZES,
    boxSizing: 'border-box',
    pt: ['sp_4', null, 'sp_8'],
    justifyContent: 'center',
    alignItems: 'center',
};
var DefaultImage = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), height, width, space, fill);
DefaultImage.defaultProps = {
    name: 'defaultProductImage',
    height: 'auto',
    width: ICON_SIZES,
    role: 'img',
    fill: 'sys.neutral.icon.inactive',
};
var DefaultProductImage = function (_a) {
    var title = _a.title, href = _a.href, props = __rest(_a, ["title", "href"]);
    return (React.createElement(DefaultImageBackground, __assign({ "data-testid": "default-product-image", title: title, "aria-label": title }, (href && { as: 'a', href: href }), props),
        React.createElement(DefaultImage, { ariaLabel: title })));
};
export default DefaultProductImage;
var templateObject_1, templateObject_2;
