import { getPageAppTrackerProps } from '../../tracking/getTrackerProps';
import { getProductEventData } from '../../tracking/productEventData';
export var getLinkTrackingProps = function (_a) {
    var href = _a.href, onClick = _a.onClick, tracking = _a.tracking, list = _a.list, index = _a.index, sponsored = _a.sponsored;
    return getPageAppTrackerProps({ href: href, onClick: onClick }, {
        eventCategory: 'product',
        event: 'productClick',
        eventData: {
            products: [
                getProductEventData({ position: index, tracking: tracking, list: list, sponsored: sponsored }),
            ],
        },
    });
};
