var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { borderBottom, borderColor, display, width, space, verticalAlign, } from 'styled-system';
import ContentProductCard from '../../common/components/ProductCard/Content/ContentProductCard';
import { useObserveArticles } from '../../common/tracking/useObserveArticles';
import { COLUMNS_COUNT_M, COLUMNS_COUNT_S, } from '../../product-list-view/components/constants';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), width, space, borderBottom, borderColor);
Container.defaultProps = {
    width: 1,
    mx: 'auto',
    mb: ['sp_16', 'sp_20', 'sp_24', 'sp_32'],
    borderBottom: 'none',
    borderColor: 'sys.neutral.background.active',
};
var ListViewItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), width, display, space, verticalAlign);
ListViewItem.defaultProps = {
    mx: 'auto',
    display: 'inline-block',
    verticalAlign: 'top',
};
var ProductCardList = function (_a) {
    var articles = _a.articles, _b = _a.columnsCount, columnsCount = _b === void 0 ? 3 : _b, listType = _a.productEventDataListType;
    useObserveArticles({ articles: articles, listType: listType });
    var listViewItemWidth = [
        1 / COLUMNS_COUNT_S,
        1 / COLUMNS_COUNT_M,
        1 / columnsCount,
    ];
    return (React.createElement(Container, { "data-testid": "list-view-inner-container" }, articles.map(function (article, index) { return (React.createElement(Fragment, { key: article.sku },
        React.createElement(ListViewItem, { width: listViewItemWidth, "data-testid": "product-item", "data-sku": article.sku },
            React.createElement(ContentProductCard, { article: article, index: index, productEventDataListType: listType })))); })));
};
export default ProductCardList;
var templateObject_1, templateObject_2;
