var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, borderRadius, width } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { Text } from '../Text';
var SponsoredText = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), backgroundColor, borderRadius, width);
SponsoredText.defaultProps = {
    color: 'sys.neutral.text.default',
    fontSize: ['fs_12', null, null, 'fs_14'],
    lineHeight: ['lh_16', null, null, 'lh_18'],
    backgroundColor: 'sys.neutral.background.weak',
    width: 'fit-content',
    px: 'sp_4',
    borderRadius: 'medium',
    mt: ['sp_8', null, null, 'sp_12'],
};
export var Sponsored = function (_a) {
    var className = _a.className;
    var t = useLocalization().t;
    return (React.createElement(SponsoredText, { className: className }, t('h24_product_card_sponsored')));
};
var templateObject_1;
