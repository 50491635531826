import { EventAction, EventCategory, ProductEvent } from '../enums/Tracking';
import dispatchTrackingEvent from './dispatchTrackingEvent';
import { getProductEventData } from './productEventData';
export var dispatchProductViewEvent = function (articles, productEventDataListType) {
    return dispatchTrackingEvent({
        eventCategory: EventCategory.Product,
        eventAction: EventAction.View,
        event: ProductEvent.View,
        eventData: {
            products: articles.map(function (_a) {
                var tracking = _a.tracking, index = _a.index, sponsored = _a.sponsored, dyDecisionId = _a.dyDecisionId, dySlotId = _a.dySlotId;
                return getProductEventData({
                    list: productEventDataListType,
                    position: index,
                    tracking: tracking,
                    sponsored: sponsored,
                    dyDecisionId: dyDecisionId,
                    dySlotId: dySlotId,
                });
            }),
        },
    });
};
