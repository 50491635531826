export var KeyCode;
(function (KeyCode) {
    KeyCode[KeyCode["Backspace"] = 8] = "Backspace";
    KeyCode[KeyCode["Enter"] = 13] = "Enter";
    KeyCode[KeyCode["Escape"] = 27] = "Escape";
    KeyCode[KeyCode["Space"] = 32] = "Space";
    KeyCode[KeyCode["ArrowUp"] = 38] = "ArrowUp";
    KeyCode[KeyCode["ArrowRight"] = 39] = "ArrowRight";
    KeyCode[KeyCode["ArrowDown"] = 40] = "ArrowDown";
    KeyCode[KeyCode["ArrowLeft"] = 37] = "ArrowLeft";
    KeyCode[KeyCode["Delete"] = 46] = "Delete";
})(KeyCode || (KeyCode = {}));
export var Key;
(function (Key) {
    Key["Tab"] = "Tab";
    Key["Esc"] = "Escape";
    Key["Space"] = " ";
    Key["Enter"] = "Enter";
    Key["ArrowUp"] = "ArrowUp";
    Key["ArrowDown"] = "ArrowDown";
    Key["ArrowLeft"] = "ArrowLeft";
    Key["ArrowRight"] = "ArrowRight";
})(Key || (Key = {}));
