var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { display } from 'styled-system';
import { useLocalization } from '../../providers/LocaleProvider';
import { Text } from '../Text';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), display);
Container.defaultProps = {
    display: 'inline-flex',
};
var StyledText = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
StyledText.defaultProps = __assign(__assign({}, Text.defaultProps), { as: 'span', pr: 'sp_4', fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_18', null, null, 'lh_24'], fontWeight: 'normal' });
var RRPPrice = function (_a) {
    var rrpPrice = _a.rrpPrice, props = __rest(_a, ["rrpPrice"]);
    var _b = useLocalization(), fc = _b.fc, r = _b.r;
    if (!(rrpPrice === null || rrpPrice === void 0 ? void 0 : rrpPrice.value)) {
        return null;
    }
    return (React.createElement(Container, null,
        React.createElement(StyledText, __assign({ "data-testid": "rrp-price" }, props), r.t('h24_rrp_value', {
            rrp: fc(rrpPrice.value),
        }))));
};
export default RRPPrice;
var templateObject_1, templateObject_2;
