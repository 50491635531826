var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { LabelType } from 'corgi-types';
import { alignItems, borderRadius, color, display, flexDirection as flexDirectionStyled, flexWrap, fontSize, fontWeight, gridGap, lineHeight, opacity, position, space, textAlign, } from 'styled-system';
import { themeBottom, themeLeft, themeTop, whiteSpace, } from '../../theme/system-utilities';
var LabelsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, themeTop, themeBottom, themeLeft, display, alignItems, flexDirectionStyled, flexWrap, gridGap);
LabelsContainer.defaultProps = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-start',
    gridGap: 'sp_8',
};
var BOTTOM_CONTAINER_PROPS = {
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    gridGap: 'sp_4',
};
var Label = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), fontSize, lineHeight, fontWeight, textAlign, color, borderRadius, space, opacity, whiteSpace);
Label.defaultProps = {
    fontSize: ['fs_14', null, null, 'fs_16'],
    lineHeight: ['lh_20', null, null, 'lh_22'],
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'sys.neutral.text.inverted',
    borderRadius: 'small',
    px: ['sp_4', null, null, 'sp_8'],
    py: ['sp_0', null, null, 'sp_4'],
    whiteSpace: 'nowrap',
};
var BOTTOM_LABEL_PROPS = {
    fontSize: ['fs_12', null, 'fs_14'],
    lineHeight: 'lh_20',
    px: 'sp_4',
    py: 'sp_0',
};
var getBackgroundColor = function (type) {
    switch (type) {
        case LabelType.Campaign:
            return 'sys.marketing.grey';
        case LabelType.New:
            return 'sys.marketing.blue';
        case LabelType.ValuePack:
            return 'sys.marketing.purple';
        default:
            return 'sys.marketing.orange';
    }
};
var DiscountLabels = function (_a) {
    var labels = _a.labels, _b = _a.themeTop, themeTopProp = _b === void 0 ? ['sp_8', 'sp_16', null, 'sp_24'] : _b, themeBottomProp = _a.themeBottom, _c = _a.themeLeft, themeLeftProp = _c === void 0 ? ['sp_8', 'sp_20', null, 'sp_32'] : _c, _d = _a.flexDirection, flexDirection = _d === void 0 ? 'column' : _d, className = _a.className;
    if (!labels.length) {
        return null;
    }
    return (React.createElement(LabelsContainer, __assign({ "data-testid": "labels-container", className: className, flexDirection: flexDirection, themeLeft: themeLeftProp }, (themeTopProp && !themeBottomProp && { themeTop: themeTopProp }), (themeBottomProp && __assign({ themeBottom: themeBottomProp }, BOTTOM_CONTAINER_PROPS))), labels.map(function (_a, index) {
        var type = _a.type, value = _a.value;
        return (React.createElement(Label, __assign({ key: index, bg: getBackgroundColor(type), "data-testid": "discount-label-".concat(index) }, (themeBottomProp && __assign({}, BOTTOM_LABEL_PROPS))), value));
    })));
};
export default DiscountLabels;
var templateObject_1, templateObject_2;
